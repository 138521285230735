// Custom.scss
// Option A: Include all of Bootstrap


/*
* Orson http://en.orson.io , autreplanete http://www.autreplanete.com/ 
*  
**/



@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";

.table th, .table td {
  padding: 0.50rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}