@font-face {
    font-family: "summernote";
    font-style: normal;
    font-weight: normal;
    /* src: url("./font/summernote.eot?dbafe969167589eda84514394d126413");
  src: url("./font/summernote.eot?#iefix") format("embedded-opentype"),
    url("./font/summernote.woff?dbafe969167589eda84514394d126413")
      format("woff"),
    url("./font/summernote.ttf") format("truetype"); Fallback option */
}

[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
    display: inline-block;
    font: normal normal normal 14px summernote;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
    speak: none;
    -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before,
.note-icon-align-indent:before,
.note-icon-align-justify:before,
.note-icon-align-left:before,
.note-icon-align-outdent:before,
.note-icon-align-right:before,
.note-icon-align:before,
.note-icon-arrow-circle-down:before,
.note-icon-arrow-circle-left:before,
.note-icon-arrow-circle-right:before,
.note-icon-arrow-circle-up:before,
.note-icon-arrows-alt:before,
.note-icon-arrows-h:before,
.note-icon-arrows-v:before,
.note-icon-bold:before,
.note-icon-caret:before,
.note-icon-chain-broken:before,
.note-icon-circle:before,
.note-icon-close:before,
.note-icon-code:before,
.note-icon-col-after:before,
.note-icon-col-before:before,
.note-icon-col-remove:before,
.note-icon-eraser:before,
.note-icon-font:before,
.note-icon-frame:before,
.note-icon-italic:before,
.note-icon-link:before,
.note-icon-magic:before,
.note-icon-menu-check:before,
.note-icon-minus:before,
.note-icon-orderedlist:before,
.note-icon-pencil:before,
.note-icon-picture:before,
.note-icon-question:before,
.note-icon-redo:before,
.note-icon-row-above:before,
.note-icon-row-below:before,
.note-icon-row-remove:before,
.note-icon-special-character:before,
.note-icon-square:before,
.note-icon-strikethrough:before,
.note-icon-subscript:before,
.note-icon-summernote:before,
.note-icon-superscript:before,
.note-icon-table:before,
.note-icon-text-height:before,
.note-icon-trash:before,
.note-icon-underline:before,
.note-icon-undo:before,
.note-icon-unorderedlist:before,
.note-icon-video:before {
    display: inline-block;
    font-family: "summernote";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}
.note-icon-align-center:before {
    content: "\f101";
}
.note-icon-align-indent:before {
    content: "\f102";
}
.note-icon-align-justify:before {
    content: "\f103";
}
.note-icon-align-left:before {
    content: "\f104";
}
.note-icon-align-outdent:before {
    content: "\f105";
}
.note-icon-align-right:before {
    content: "\f106";
}
.note-icon-align:before {
    content: "\f107";
}
.note-icon-arrow-circle-down:before {
    content: "\f108";
}
.note-icon-arrow-circle-left:before {
    content: "\f109";
}
.note-icon-arrow-circle-right:before {
    content: "\f10a";
}
.note-icon-arrow-circle-up:before {
    content: "\f10b";
}
.note-icon-arrows-alt:before {
    content: "\f10c";
}
.note-icon-arrows-h:before {
    content: "\f10d";
}
.note-icon-arrows-v:before {
    content: "\f10e";
}
.note-icon-bold:before {
    content: "\f10f";
}
.note-icon-caret:before {
    content: "\f110";
}
.note-icon-chain-broken:before {
    content: "\f111";
}
.note-icon-circle:before {
    content: "\f112";
}
.note-icon-close:before {
    content: "\f113";
}
.note-icon-code:before {
    content: "\f114";
}
.note-icon-col-after:before {
    content: "\f115";
}
.note-icon-col-before:before {
    content: "\f116";
}
.note-icon-col-remove:before {
    content: "\f117";
}
.note-icon-eraser:before {
    content: "\f118";
}
.note-icon-font:before {
    content: "\f119";
}
.note-icon-frame:before {
    content: "\f11a";
}
.note-icon-italic:before {
    content: "\f11b";
}
.note-icon-link:before {
    content: "\f11c";
}
.note-icon-magic:before {
    content: "\f11d";
}
.note-icon-menu-check:before {
    content: "\f11e";
}
.note-icon-minus:before {
    content: "\f11f";
}
.note-icon-orderedlist:before {
    content: "\f120";
}
.note-icon-pencil:before {
    content: "\f121";
}
.note-icon-picture:before {
    content: "\f122";
}
.note-icon-question:before {
    content: "\f123";
}
.note-icon-redo:before {
    content: "\f124";
}
.note-icon-row-above:before {
    content: "\f125";
}
.note-icon-row-below:before {
    content: "\f126";
}
.note-icon-row-remove:before {
    content: "\f127";
}
.note-icon-special-character:before {
    content: "\f128";
}
.note-icon-square:before {
    content: "\f129";
}
.note-icon-strikethrough:before {
    content: "\f12a";
}
.note-icon-subscript:before {
    content: "\f12b";
}
.note-icon-summernote:before {
    content: "\f12c";
}
.note-icon-superscript:before {
    content: "\f12d";
}
.note-icon-table:before {
    content: "\f12e";
}
.note-icon-text-height:before {
    content: "\f12f";
}
.note-icon-trash:before {
    content: "\f130";
}
.note-icon-underline:before {
    content: "\f131";
}
.note-icon-undo:before {
    content: "\f132";
}
.note-icon-unorderedlist:before {
    content: "\f133";
}
.note-icon-video:before {
    content: "\f134";
}
.note-editor {
    position: relative;
}
.note-editor .note-dropzone {
    position: absolute;
    z-index: 100;
    display: none;
    color: #87cefa;
    background-color: #fff;
    opacity: 0.95;
}
.note-editor .note-dropzone .note-dropzone-message {
    display: table-cell;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
}
.note-editor .note-dropzone.hover {
    color: #098ddf;
}
.note-editor.dragover .note-dropzone {
    display: table;
}
.note-editor .note-editing-area {
    position: relative;
}
.note-editor .note-editing-area .note-editable {
    outline: 0;
}
.note-editor .note-editing-area .note-editable sup {
    vertical-align: super;
}
.note-editor .note-editing-area .note-editable sub {
    vertical-align: sub;
}
.note-editor .note-editing-area img.note-float-left {
    margin-right: 10px;
}
.note-editor .note-editing-area img.note-float-right {
    margin-left: 10px;
}
.note-editor.note-frame {
    border: 1px solid #a9a9a9;
}
.note-editor.note-frame.codeview .note-editing-area .note-editable {
    display: none;
}
.note-editor.note-frame.codeview .note-editing-area .note-codable {
    display: block;
}
.note-editor.note-frame .note-editing-area {
    overflow: hidden;
}
.note-editor.note-frame .note-editing-area .note-editable {
    padding: 10px;
    overflow: auto;
    color: #000;
    word-wrap: break-word;
    background-color: #fff;
}
.note-editor.note-frame .note-editing-area .note-editable[contenteditable="false"] {
    background-color: #e5e5e5;
}
.note-editor.note-frame .note-editing-area .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 0;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
}
.note-editor.note-frame.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100% !important;
}
.note-editor.note-frame.fullscreen .note-editable {
    background-color: #fff;
}
.note-editor.note-frame.fullscreen .note-resizebar {
    display: none;
}
.note-editor.note-frame .note-status-output {
    display: block;
    width: 100%;
    height: 20px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    border: 0;
    border-top: 1px solid #e2e2e2;
}
.note-editor.note-frame .note-status-output:empty {
    height: 0;
    border-top: 0 solid transparent;
}
.note-editor.note-frame .note-status-output .pull-right {
    float: right !important;
}
.note-editor.note-frame .note-status-output .text-muted {
    color: #777;
}
.note-editor.note-frame .note-status-output .text-primary {
    color: #286090;
}
.note-editor.note-frame .note-status-output .text-success {
    color: #3c763d;
}
.note-editor.note-frame .note-status-output .text-info {
    color: #31708f;
}
.note-editor.note-frame .note-status-output .text-warning {
    color: #8a6d3b;
}
.note-editor.note-frame .note-status-output .text-danger {
    color: #a94442;
}
.note-editor.note-frame .note-status-output .alert {
    padding: 7px 10px 2px 10px;
    margin: -7px 0 0 0;
    color: #000;
    background-color: #f5f5f5;
    border-radius: 0;
}
.note-editor.note-frame .note-status-output .alert .note-icon {
    margin-right: 5px;
}
.note-editor.note-frame .note-status-output .alert-success {
    color: #3c763d !important;
    background-color: #dff0d8 !important;
}
.note-editor.note-frame .note-status-output .alert-info {
    color: #31708f !important;
    background-color: #d9edf7 !important;
}
.note-editor.note-frame .note-status-output .alert-warning {
    color: #8a6d3b !important;
    background-color: #fcf8e3 !important;
}
.note-editor.note-frame .note-status-output .alert-danger {
    color: #a94442 !important;
    background-color: #f2dede !important;
}
.note-editor.note-frame .note-statusbar {
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.note-editor.note-frame .note-statusbar .note-resizebar {
    width: 100%;
    height: 9px;
    padding-top: 1px;
    cursor: ns-resize;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid #a9a9a9;
}
.note-editor.note-frame .note-statusbar.locked .note-resizebar {
    cursor: default;
}
.note-editor.note-frame .note-statusbar.locked .note-resizebar .note-icon-bar {
    display: none;
}
.note-editor.note-frame .note-placeholder {
    padding: 10px;
}
.note-popover.popover {
    display: none;
    max-width: none;
}
.note-popover.popover .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}
.note-popover.popover .arrow {
    left: 20px !important;
}
.note-toolbar {
    position: relative;
    z-index: 500;
}
.note-popover .popover-content,
.card-header.note-toolbar {
    padding: 0 0 5px 5px;
    margin: 0;
}
.note-popover .popover-content > .btn-group,
.card-header.note-toolbar > .btn-group {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 0;
}
.note-popover .popover-content .btn-group .note-table,
.card-header.note-toolbar .btn-group .note-table {
    min-width: 0;
    padding: 5px;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker,
.card-header.note-toolbar .btn-group .note-table .note-dimension-picker {
    font-size: 18px;
}
.note-popover
    .popover-content
    .btn-group
    .note-table
    .note-dimension-picker
    .note-dimension-picker-mousecatcher,
.card-header.note-toolbar
    .btn-group
    .note-table
    .note-dimension-picker
    .note-dimension-picker-mousecatcher {
    position: absolute !important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer;
}
.note-popover
    .popover-content
    .btn-group
    .note-table
    .note-dimension-picker
    .note-dimension-picker-unhighlighted,
.card-header.note-toolbar
    .btn-group
    .note-table
    .note-dimension-picker
    .note-dimension-picker-unhighlighted {
    position: relative !important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
        repeat;
}
.note-popover
    .popover-content
    .btn-group
    .note-table
    .note-dimension-picker
    .note-dimension-picker-highlighted,
.card-header.note-toolbar
    .btn-group
    .note-table
    .note-dimension-picker
    .note-dimension-picker-highlighted {
    position: absolute !important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
        repeat;
}
.note-popover .popover-content .note-style .dropdown-style blockquote,
.card-header.note-toolbar .note-style .dropdown-style blockquote,
.note-popover .popover-content .note-style .dropdown-style pre,
.card-header.note-toolbar .note-style .dropdown-style pre {
    padding: 5px 10px;
    margin: 0;
}
.note-popover .popover-content .note-style .dropdown-style h1,
.card-header.note-toolbar .note-style .dropdown-style h1,
.note-popover .popover-content .note-style .dropdown-style h2,
.card-header.note-toolbar .note-style .dropdown-style h2,
.note-popover .popover-content .note-style .dropdown-style h3,
.card-header.note-toolbar .note-style .dropdown-style h3,
.note-popover .popover-content .note-style .dropdown-style h4,
.card-header.note-toolbar .note-style .dropdown-style h4,
.note-popover .popover-content .note-style .dropdown-style h5,
.card-header.note-toolbar .note-style .dropdown-style h5,
.note-popover .popover-content .note-style .dropdown-style h6,
.card-header.note-toolbar .note-style .dropdown-style h6,
.note-popover .popover-content .note-style .dropdown-style p,
.card-header.note-toolbar .note-style .dropdown-style p {
    padding: 0;
    margin: 0;
}
.note-popover .popover-content .note-color .dropdown-toggle,
.card-header.note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px;
}
.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
    min-width: 337px;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette,
.card-header.note-toolbar .note-color .dropdown-menu .note-palette {
    display: inline-block;
    width: 160px;
    margin: 0;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette:first-child,
.card-header.note-toolbar .note-color .dropdown-menu .note-palette:first-child {
    margin: 0 5px;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette .note-palette-title,
.card-header.note-toolbar .note-color .dropdown-menu .note-palette .note-palette-title {
    margin: 2px 7px;
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid #eee;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette .note-color-reset,
.card-header.note-toolbar .note-color .dropdown-menu .note-palette .note-color-reset {
    width: 100%;
    padding: 0 3px;
    margin: 3px;
    font-size: 11px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette .note-color-row,
.card-header.note-toolbar .note-color .dropdown-menu .note-palette .note-color-row {
    height: 20px;
}
.note-popover .popover-content .note-color .dropdown-menu .note-palette .note-color-reset:hover,
.card-header.note-toolbar .note-color .dropdown-menu .note-palette .note-color-reset:hover {
    background: #eee;
}
.note-popover .popover-content .note-para .dropdown-menu,
.card-header.note-toolbar .note-para .dropdown-menu {
    min-width: 216px;
    padding: 5px;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child,
.card-header.note-toolbar .note-para .dropdown-menu > div:first-child {
    margin-right: 5px;
}
.note-popover .popover-content .dropdown-menu,
.card-header.note-toolbar .dropdown-menu {
    min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.card-header.note-toolbar .dropdown-menu.right {
    right: 0;
    left: auto;
}
.note-popover .popover-content .dropdown-menu.right::before,
.card-header.note-toolbar .dropdown-menu.right::before {
    right: 9px;
    left: auto !important;
}
.note-popover .popover-content .dropdown-menu.right::after,
.card-header.note-toolbar .dropdown-menu.right::after {
    right: 10px;
    left: auto !important;
}
.note-popover .popover-content .dropdown-menu.note-check a i,
.card-header.note-toolbar .dropdown-menu.note-check a i {
    color: deepskyblue;
    visibility: hidden;
}
.note-popover .popover-content .dropdown-menu.note-check a.checked i,
.card-header.note-toolbar .dropdown-menu.note-check a.checked i {
    visibility: visible;
}
.note-popover .popover-content .note-fontsize-10,
.card-header.note-toolbar .note-fontsize-10 {
    font-size: 10px;
}
.note-popover .popover-content .note-color-palette,
.card-header.note-toolbar .note-color-palette {
    line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.card-header.note-toolbar .note-color-palette div .note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 1px solid #fff;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.card-header.note-toolbar .note-color-palette div .note-color-btn:hover {
    border: 1px solid #000;
}
.note-dialog > div {
    display: none;
}
.note-dialog .form-group {
    margin-right: 0;
    margin-left: 0;
}
.note-dialog .note-modal-form {
    margin: 0;
}
.note-dialog .note-image-dialog .note-dropzone {
    min-height: 100px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 4;
    color: lightgray;
    text-align: center;
    border: 4px dashed lightgray;
}
@-moz-document url-prefix() {
    .note-image-input {
        height: auto;
    }
}
.note-placeholder {
    position: absolute;
    display: none;
    color: gray;
}
.note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid black;
}
.note-handle .note-control-selection > div {
    position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-opacity: 0.3;
    -khtml-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: alpha(opacity=30);
    filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle {
    width: 7px;
    height: 7px;
    border: 1px solid black;
}
.note-handle .note-control-selection .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid black;
}
.note-handle .note-control-selection .note-control-sizing {
    width: 7px;
    height: 7px;
    background-color: white;
    border: 1px solid black;
}
.note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: 0;
    border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: 0;
    border-left: none;
}
.note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: 0;
    border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
}
.note-handle .note-control-selection .note-control-se.note-control-holder {
    cursor: default;
    border-top: 0;
    border-left: none;
}
.note-handle .note-control-selection .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    color: #fff;
    background-color: #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-opacity: 0.7;
    -khtml-opacity: 0.7;
    -moz-opacity: 0.7;
    opacity: 0.7;
    -ms-filter: alpha(opacity=70);
    filter: alpha(opacity=70);
}
.note-hint-popover {
    min-width: 100px;
    padding: 2px;
}
.note-hint-popover .popover-content {
    max-height: 150px;
    padding: 3px;
    overflow: auto;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item {
    display: block !important;
    padding: 3px;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item.active,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    background-color: #428bca;
    outline: 0;
}
