@import "../../variables";

@font-face {
  font-family: "Bell MT";

  src: url("../../../fonts/BellMTStd-SemiBold.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Bell MT";
  src: url("../../../fonts/bell-mt-bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Bell MT";
  src: url("../../../fonts/bell-mt-bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
}

#root {
  padding-top: 74px;
  padding-bottom: 56px;

  @media (max-width: 991px) {
    padding-bottom: 85px;
    padding-top: 68px;
  }
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz21 {
  font-size: 21px;
}

.fz22 {
  font-size: 22px;
}

.fz23 {
  font-size: 23px;
}

.fz24 {
  font-size: 24px;
}

.fz25 {
  font-size: 25px;
}

.fz26 {
  font-size: 26px;
}

.font-mon {
  font-family: "Montserrat", sans-serif;
}

.btn {
  &.btn-round {
    border-radius: 25px;
  }

  &.btn-secondary {
    color: #fff;
    font-weight: bold;
  }
}

.form-error-message {
  color: $red;
  font-size: 12px;
}

.design-system-block {
  margin-bottom: 30px;
}

.modal-footer-buttons {
  &>* {
    width: 50%;
  }
}

.modal-open {
  .navbar-sidenav {
    @media (min-width: 992px) {
      display: none;
    }
  }
}

// #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
//   @media (max-width:991px) {
//     display: flex;
//     align-items: center;
//   }
// }

.badge-x1 {
  font-size: 20px;
  padding: 7px;
  border-radius: 25px;
}

.verify_me_tooltip {
  h6 {
    text-align: left;
  }

  .tooltip {
    opacity: 1 !important;

    .tooltip-inner {
      background-color: $gray-400;
      color: #000;
      padding: 16px;
      border-radius: 8px;
      max-width: 400px;

      ul {
        margin: 0;

        li {
          text-align: left;
          margin-bottom: 4px;

          i {
            margin-right: 4px;
          }

          &.active {
            color: #03a629;
          }

          &.inactive {
            // color: $red;
            color: #333;
          }
        }
      }
    }

    .arrow {}
  }
}

.input-group-prepend {
  cursor: pointer;
}

.common-box-shadow {
  background-color: #fff;
  border-radius: 6px;

  box-shadow: 0 0 30px #00000080;
}

.color-bar {
  background-color: #000;
  border-radius: 10px;
  height: 24px;
  width: 90px;
  max-width: 90px;
  margin-right: auto;
  margin-left: auto;
  display: inline-block;

  &.red {
    background-color: red;
  }

  &.yellow {
    background-color: yellow;
  }

  &.green {
    background-color: green;
  }
}

.sortable {
  cursor: pointer;
}

.new-table-update {
  .sortable {
    cursor: pointer;

    i {
      opacity: 0;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }

    i {
      &.activeSort {
        opacity: 1;
      }
    }
  }
}

i.two {
  font-size: 120%;
  font-style: normal;
}

.nav-icon3 {
  width: 28px;
  height: 24px;
  position: relative;
  display: inline-block;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  @media (max-width: 991px) {
    width: 20px;
    height: 16px;
  }

  &:hover {
    span {
      background-color: $red;
    }
  }

  &:hover {
    span {
      background-color: $red;
    }
  }

  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #000;
    border-radius: 0px;
    opacity: 1;
    left: 0;

    transform: rotate(0deg);

    transition: 0.25s ease-in-out;

    @media (max-width: 991px) {
      height: 3px;
    }

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 8px;

      @media (max-width: 991px) {
        top: 6px;
      }
    }

    &:nth-child(3) {
      top: 8px;

      @media (max-width: 991px) {
        top: 6px;
      }
    }

    &:nth-child(4) {
      top: 16px;

      @media (max-width: 991px) {
        top: 12px;
      }
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 12px;
        width: 0%;
        left: 50%;

        @media (max-width: 991px) {
          top: 6px;
        }
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 12px;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.menu-collapse-wrapper {
  @media (max-width: 991px) {}
}

.primary-authenticated-menu-all {

  font-size: 20px;
  max-width: 450px;
  margin-left: auto;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 125%;
  /* 25px */
  letter-spacing: -0.2px;
  margin-top: 72px;
  // padding-top: 8px;

  @media (max-width: 991px) {
    margin-top: 68px;
  }

  .container {

    @media (min-width: 992px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  .primary-auth-desktop-menu {
    padding: 40px 0;

    @media (max-width: 991px) {
      display: none;
    }

    .my-masonry-grid {
      margin-top: 36px;
    }

    .menu-column {




      h5 {
        margin-bottom: 6px;
        padding: 10px;
        font-size: 20px;
        color: #fff !important;

        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        /* 30px */
        letter-spacing: -0.24px;
      }

      ul {
        margin-bottom: 10px;
        padding-left: 8px;

        li {
          // background-color: #fff;
          margin-bottom: 6px;

          a {
            color: #fff;
            padding: 10px;
            font-size: 15px;

            font-weight: 500;
            line-height: 125%;
            /* 22.5px */
            letter-spacing: -0.18px;
            position: relative;
            padding-left: 26px;
            display: block;

            &::before {
              content: "";
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3E%3Cpath d='M8.56152 1.11536L15.9996 8.49587L8.56152 15.8846' stroke='white' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M15.3627 8.49585H-0.000976562' stroke='white' stroke-width='1.5' stroke-miterlimit='10'/%3E%3C/svg%3E");
              background-size: 16px 14px;
              position: absolute;
              left: 0;
              top: calc(50% - 7px);
              width: 16px;
              height: 14px;
            }

            &:hover {}

            // &:hover{
            //   color: #000;
            //   &::before {

            //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3E%3Cpath d='M8.56152 1.11536L15.9996 8.49587L8.56152 15.8846' stroke='black' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M15.3627 8.49585H-0.000976562' stroke='black' stroke-width='1.5' stroke-miterlimit='10'/%3E%3C/svg%3E");

            //   }
            // }
          }
        }
      }
    }
  }

  .primary-auth-mobile-menu {
    margin-bottom: 0;
    // display: none;

    max-height: 90vh;
    overflow: auto;

    @media (max-width: 991px) {
      display: block;
      height: 90vh;
      overflow: auto;
    }

    .link-item {
      color: #fff;
      padding: 10px 32px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;

      @media (max-width: 991px) {
        font-size: 16px;
        padding: 8px 32px;
      }
    }

    .single-item {}

    .has-nested-item {
      background-color: $red;
      color: #fff;
      border-bottom: 1px solid #fff;


      &.expanded {
        background-color: #d31b02;

        svg {
          transform: rotate(180deg);
        }
      }

      li {
        a {}
      }
    }
  }
}

.navigation-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navigation-primary {
  font-family: "Montserrat", sans-serif;
  padding: 0 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;

  @media (max-width: 991px) {
    padding: 0 16px !important;
    min-height: 50px;
  }

  .brand-logo {
    display: flex;
    align-content: center;

    img {
      height: 34px;

      @media (max-width: 991px) {
        height: 20px;
      }

      @media (max-width: 380px) {
        height: 18px;
      }
    }
  }

  .nav-toggle {
    display: none;
    margin-left: 32px;
    cursor: pointer;

    @media (max-width: 991px) {
      margin-left: 0px;
      padding-right: 0px;
    }

    :hover {
      span {
        background-color: $red;
      }
    }

    &.isAuth {
      display: inline-flex;
    }

    @media (max-width: 991px) {
      display: inline-flex !important;
    }
  }

  .nav {
    li {
      .nav-link {
        font-family: "Montserrat", sans-serif;
        color: #000;
        font-size: 18px;

        font-weight: 400;
        margin-left: 16px;
        margin-right: 16px;
        padding: 10px;

        &:hover {
          color: $red;
        }
      }
    }
  }

  .right-nav-buttons {
    display: flex;
    align-items: center;
    padding-right: 16px;
    justify-content: flex-start;
    justify-content: flex-start;

    @media (max-width: 991px) {
      padding-right: 0;
    }

    .btn {
      color: $red;
      font-family: "Montserrat", sans-serif;
      text-decoration: none;
      font-size: 18px;

      font-weight: 500;
      display: inline-block;

      // padding: 16px 20px 16px 0px;
      @media (max-width: 991px) {
        // padding: 8px 10px 8px 0px;
        font-size: 14px;
        padding-left: 8px;
        padding-right: 8px;
      }

      &:first-child {

        // padding-left: 0;
        @media (max-width: 991px) {
          // padding-right: 0;
        }
      }

      &:last-child {

        // padding: 16px 8px 16px 20px;
        @media (max-width: 991px) {
          padding-right: 0;
        }
      }

      &:hover {
        color: #000;
      }
    }
  }

  .non-auth-menu {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.navigation-secondary {
  font-family: "Montserrat", sans-serif;
  padding: 5px 24px;

  background-color: $red;
  color: #fff;

  .nav {
    gap: 16px;

    li {
      .nav-link {
        font-family: "Montserrat", sans-serif;
        color: #fff;
        font-size: 18px;

        font-weight: 400;
        margin-left: 16px;
        margin-right: 16px;
        padding: 10px;

        &:hover {
          color: #000;
        }
      }
    }
  }

  .dropdown-menu {
    min-width: 260px;
    padding: 0;
    background: transparentize($color: #d31b02, $amount: 0.2);

    .dropdown-item {
      padding: 0;

      &:hover {
        background-color: $red;
      }

      &:active {
        background-color: $red;
      }

      a {
        padding: 17px 26px;
        color: #fff;
        display: block;
      }
    }
  }
}

.folder-comp {
  // margin-top: 80px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }

  .title {
    background-color: #f2f2f2;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 24px 32px;
    margin-bottom: 0;

    @media (max-width: 991px) {
      display: inline-block;
      width: auto;
    }

    h4 {
      font-weight: bold;

      @media (max-width: 991px) {
        font-size: 24px;
      }
    }
  }

  .body-section {
    background-color: #f2f2f2;
    font-family: "Montserrat", sans-serif;

    .data-column {
      padding: 80px 40px;

      @media (max-width: 991px) {
        padding: 16px;
      }
    }

    .updateDate {
      // font-family: "Montserrat", sans-serif;
      margin-bottom: 26px;

      @media (max-width: 991px) {
        font-weight: bold;
      }
    }

    .data-column {
      &.chart {
        padding: 80px;

        @media (max-width: 991px) {
          padding: 16px;
        }
      }
    }
  }

  &.DashComponentCommon {
    .title {




      h4 {
        font-weight: bold;

        @media (max-width: 991px) {
          font-size: 26px;
        }
      }
    }

    .body-section {
      .data-column {
        .updateDate {
          font-weight: normal;
        }
      }
    }
  }
}

.dash-table {
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      display: inline;
      align-items: center;
      color: #000;

      &.label {
        font-size: 18px;
        font-weight: 400;

        @media (max-width: 991px) {
          font-size: 13px;
          padding-right: 20px;
          width: 64%;
          position: relative;

          .tooltip-info-icon {
            position: absolute;
            top: 0px;
            right: 0;
          }
        }

        // .tooltip-info-icon {
        //   cursor: pointer;
        //   display: inline-block;
        //   margin-left: 8px;
        //   svg {
        //     height: 20px;
        //     path {
        //       fill: #b3b3b3;
        //     }
        //   }
        // }
      }

      &.value {
        font-size: 24px;

        font-style: normal;
        font-weight: 500;

        @media (max-width: 991px) {
          font-size: 15px;
        }
      }
    }
  }
}

.float-text {
  border-radius: 50px;

  padding: 43px 27px;
  position: relative;
  min-height: 275px;
  display: inline-block;

  @media (max-width: 991px) {
    min-height: auto;
    margin-bottom: 90px;
    display: block;
    padding: 18px 16px;
  }

  .overlay {
    position: absolute;
    width: 100vw;
    height: 100%;
    border-radius: 50px;
    z-index: 0;
    right: 0;
    top: 0;

    @media (max-width: 991px) {
      width: 200vw;
    }
  }

  .text-content {
    position: relative;
    z-index: 1;

    h1 {
      @media (max-width: 991px) {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    h4 {
      @media (max-width: 991px) {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-size: 40px;
    margin-bottom: 0;

    @media (max-width: 991px) {
      font-size: 24px;
    }
  }

  .sub-title {
    font-size: 24px;
  }

  &.red-bg {

    // opacity: 0.7;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #fff;
    }

    .overlay {
      // background: $red;
      background: rgba(255, 30, 0, 0.7);
    }
  }

  &.yellow-bg {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $red;
    }

    .overlay {
      // background: $yellow;
      // opacity: 0.9;
      background: $yellow;
    }
  }

  &.black-bg {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #fff;
    }

    .overlay {
      // background: $yellow;
      // opacity: 0.9;
      background: #000;
    }
  }

  &.black-color {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #000;
    }
  }

  &.min-height-auto {
    min-height: auto;
  }

  &.page-head {
    margin-bottom: 80px;
    margin-top: 27px;

    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  &.right {

    .overlay {
      right: auto;
      left: 0;
    }

  }
}

.float-text-v2 {
  border-radius: 50px;

  // padding: 43px 27px;
  padding: 56px 20px;
  padding-right: 40px;
  position: relative;
  max-height: 100%;
  width: 95%;
  display: inline-flex;
  align-items: center;

  &.right {
    padding-left: 72px;
  }

  @media (max-width: 991px) {
    min-height: auto;
    margin-bottom: 90px;
    display: block;
    padding: 18px 16px;
  }

  .overlay {
    position: absolute;
    width: 100vw;
    height: 100%;
    border-radius: 50px;
    z-index: 0;
    right: 0;
    top: 0;

    @media (max-width: 991px) {
      width: 200vw;
    }
  }

  .text-content {
    position: relative;
    z-index: 1;

    h1 {
      @media (max-width: 991px) {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    h4 {
      @media (max-width: 991px) {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-size: 40px;
    margin-bottom: 0;

    @media (max-width: 991px) {
      font-size: 24px;
    }
  }

  .sub-title {
    font-size: 24px;
  }

  &.red-bg {

    // opacity: 0.7;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #fff;
    }

    .overlay {
      // background: $red;
      background: rgba(255, 30, 0, 0.7);
    }
  }

  &.yellow-bg {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $red;
    }

    .overlay {
      // background: $yellow;
      // opacity: 0.9;
      background: $yellow;
    }
  }

  &.black-bg {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #fff;
    }

    .overlay {
      // background: $yellow;
      // opacity: 0.9;
      background: #000;
    }
  }

  &.black-color {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #000;
    }
  }

  &.min-height-auto {
    min-height: auto;
  }

  &.page-head {
    // margin-bottom: 80px;
    // margin-top: 27px;

    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  &.right {

    .overlay {
      right: auto;
      left: 0;
    }

  }
}

.container-based-wrapper {
  margin-top: 25px;
}

.table-new-sort-filter {
  tr {
    th {
      vertical-align: middle;

      .sortable {
        cursor: pointer;

        i {
          opacity: 0;

          &.activeSort {
            opacity: 1;
          }
        }

        &:hover {
          i {
            opacity: 1;
          }
        }
      }
    }
  }
}

.payout-row {
  border: 1px solid #787878;
  background-color: #eee;
  margin-bottom: 16px;
  padding-top: 16px;
  border-radius: 4px;

  .table-responsive {
    margin-top: 8px;
  }
}

.ql-container {
  font-family: "Bell MT";
}

.static-text-content-page {
  font-family: "Bell MT";

  img {
    max-width: 100%;
  }

  p {
    font-size: 16px;
    margin-bottom: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-size: 24px;
    margin-bottom: 20px;
  }

  ul,
  ol {
    // padding-left: 16px;
    margin-left: 16px;
  }

  ul {
    list-style-type: disc;
    margin-bottom: 16px;
    padding-inline-start: 16px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-bottom: 4px;

    li {
      margin-bottom: 4px;
    }
  }

  ol {
    list-style-type: decimal;
    margin-bottom: 16px;
    padding-inline-start: 16px;
    margin-block-start: 1em;
    margin-block-end: 1em;

    li {
      margin-bottom: 4px;
    }

    ol {
      list-style: lower-alpha;

      ol {
        list-style: lower-roman;

        ol {
          list-style-type: decimal;

          ol {
            list-style: lower-roman;
          }
        }
      }
    }
  }
}

ol.num {
  list-style-type: decimal;
  margin-bottom: 16px;
  padding-inline-start: 16px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

ol.abc {
  list-style: lower-alpha;
  padding-left: 24px;
}

.ql-video {
  width: 100%;
  height: 600px;

  @media (max-height: 700px) {
    height: 50vh;
  }
}

.ql-snow .ql-tooltip {
  left: 0 !important;
}

.data-block-list {
  margin-top: 20px;
  margin-bottom: 60px;

  .data-block {
    margin-bottom: 10px;

    h5 {
      margin-bottom: 0;
      font-weight: bold;
    }

    p {
      margin-bottom: 0;

      b {}
    }
  }
}


.sticky-table-cell {
  border: 1px solid rgb(229, 229, 229);
}

.tr-font {
  * {
    font-family: "Montserrat", sans-serif;
  }

}

.card-ui-pages-content-wrapper {
  background-color: #DEE1E6 !important;

  .card,
  .card-header {
    background-color: transparent;
  }
}

.SelectComponent {
  // position: relative;
  // z-index: 1;
}

.tr-card-list {
  height: 58vh;
  overflow-y: auto;
}

.tr-card {
  display: flex;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  .tr-info-wrap {
    padding-right: 16px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;

    }


  }

  .TRInfo {

    .value {
      margin-bottom: 0;
      font-weight: bold;
      // color: #787D8A;
      color: #222;
      font-family: "Montserrat", sans-serif;
      padding-right: 8px;
      word-wrap: break-word;
    }

    .label {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 600;
      color: darken($color: #b9babe, $amount: 10);
      font-family: "Montserrat", sans-serif;
    }
  }

  .ic-label-wrap {
    margin-bottom: 6px;

  }

  .info-icon-wrap {
    flex-basis: 300px;
    min-width: 300px;


    @media (max-width: 991px) {
      flex-basis: 100%;
      min-width: 100%;
      border-bottom: 1px solid #ddd;
      padding-bottom: 8px;
      margin-bottom: 16px;
      // margin-right: -32px;
      // margin-left: -16px;
      // padding-left: 16px;
      // padding-right: 16px;
    }

    .ic-label {
      display: inline-flex;
      font-size: 12px;
      font-weight: 600;
      align-items: center;
      color: #000;
      font-family: "Montserrat", sans-serif;

      &.up {
        span {
          // color: $red;
        }

        svg {
          transform: rotate(180deg);

          path {
            stroke: $red;
            stroke: #03a629;
          }
        }
      }

      &.withbg {
        padding: 4px 8px;
        background-color: #D8F6CA;
        border-radius: 16px;
        padding-left: 2px;
      }

      svg {
        margin-right: 8px;
        height: 20px;

        width: auto;

        path {
          stroke: #03a629;
        }
      }

      span {}
    }
  }

  .info-text {
    flex-grow: 1;

    @media (max-width: 991px) {
      flex-basis: 100%;
      min-width: 100%;
    }

    .comments {
      color: #222;
      font-family: "Montserrat", sans-serif;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .info-text-col-wrap {

    display: flex;
    flex-wrap: wrap;

    .info-col {
      width: 33%;
      min-width: 250px;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      margin-bottom: 12px;





      .label {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 600;
        color: darken($color: #b9babe, $amount: 10);
        font-family: "Montserrat", sans-serif;
      }
    }


  }


}

.tr-filter-wrapper {
  margin-bottom: 16px;
  background-color: #fff;
  padding-top: 32px;
  padding-bottom: 16px;
  border-radius: 6px;
  padding-right: 16px;
  padding-left: 16px;

  .btn-primary {
    min-height: 38px;
  }

  .double-datepicker {
    display: flex;

    .SingleDatePickerComponent {
      width: 50%;

      &:first-child {

        // padding-right: 16px;
        .SingleDatePickerInput {
          // border-right-width: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

        }
      }

      &:last-child {


        // padding-left: 16px;
        .SingleDatePickerInput {
          border-left-width: 0px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .SingleDatePicker {
      display: block;

      .SingleDatePickerInput {
        border-radius: 4px;
        display: block;
        border-color: #ccc;
      }
    }

    .DateInput {
      width: 100%;
      display: block;

      .DateInput_input {
        width: 100%;
        display: block;
        height: 36.5px;
        font-size: 14px;
        line-height: 1;
        padding: 10px 11px 9px;
      }
    }
  }



  .tr-filter-row {
    display: flex;
    flex-wrap: wrap;

    .tr-col {
      padding: 0 16px;
      min-width: 16.6666666667%;
      flex-basis: 16.6666666667%;
      margin-bottom: 16px;

      @media (max-width: 1400px) {
        min-width: 33.3333333334%;
        flex-basis: 33.3333333334%;
      }

      @media (max-width: 1024px) {
        min-width: 100%;

      }

      &.two {
        min-width: 33.3333333334%;
        flex-basis: 33.3333333334%;

        @media (max-width: 1400px) {
          min-width: 66.3333333334%;
          flex-basis: 66.3333333334%;
        }

        @media (max-width: 1024px) {
          min-width: 100%;

        }

      }

    }


  }




  * {
    font-family: "Montserrat", sans-serif;
  }


}

.text-editor-wrapper {
  position: relative;

  .LoadingBlock {
    position: absolute;
    width: 100%;
    height: 100% !important;
    z-index: 1;
    // background: linear-gradient(100deg, #3B53D6, #4AFAFA);
    // filter: blur(2px);
  }
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  // background: grey;
  // margin-bottom: 30px;
}

.primary-admin-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #ededed;
  /* background-color: #fff */
  background-color: #27313f;
  padding: 10px 0;
  color: #fff;
  font-size: 14px;

  .cname {
    font-size: 18px;
    color: #fff;
  }

}